import { HTTP } from '../../axios/axios'

async function getAll (lang) {
  try {
    const res = await HTTP.get('/meta', { headers: { 'accept-language': lang } })
    return res.data
  } catch (error) {
    console.log(error)
  }
}
async function addOne (meta, lang) {
  meta.lang = lang
  return await HTTP.post('/meta', meta)
}
async function update (meta, id) {
  return await HTTP.patch('/meta/' + id, meta)
}
async function deleteOne (meta) {
  return await HTTP.delete('/meta/' + meta._id)
}
async function deleteMany (arr) {
  return await HTTP.post('/meta/many', { _id: arr })
}

export default { getAll, deleteMany, addOne, update, deleteOne }
